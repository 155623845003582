
				var addMethods = require("../../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["processUsingAST","compile"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "ef975286c1b4158f1ba3.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "ef975286c1b4158f1ba3.worker.js");
					addMethods(w, methods)
					
					return w
				}
			